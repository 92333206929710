import React, { Component } from 'react';

import BGArrowPointerBottomLeft from '../../assets/images/bg-arrow-pointer-bottom-left.inline.svg';
import BGArrowPointerBottomRight from '../../assets/images/bg-arrow-pointer-bottom-right.inline.svg';
import BGArrowPointerTopLeft from '../../assets/images/bg-arrow-pointer-top-left.inline.svg';
import BGArrowPointerTopRight from '../../assets/images/bg-arrow-pointer-top-right.inline.svg';

import './ShapeBackground.scss';

let classNames = require('classnames');

class ShapeBackground extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { top, bottom, color, bottomInfinite, bottomInversebg, children, arrowRightWithNoMarge, secondaryColor } =
      this.props;
    let bg_color = null
    switch (color) {
      case 'bleu':
        bg_color = 'dark_bleu';
        break;
      case 'dark_bleu':
        bg_color = color;
        break;
      case 'catskill_dark':
        bg_color = color;
        break;
      case 'catskill_white':
        bg_color = color;
        break;
      case 'white':
        bg_color = color;
        break;
      default:
        bg_color = null;
    }
    const arrow_top = bg_color ? top : false;
    const arrow_bottom = bg_color ? bottom : false;
    const is_bottomInfinite = bg_color ? bottomInfinite : false;
    return (
      <div
        className={classNames(
          'shaped_background',
          bg_color ? bg_color : '',
          arrow_top ? 'top_' + arrow_top + (arrowRightWithNoMarge ? '_no_marge' : '') : 'no_top',
          arrow_bottom ? 'bottom_' + arrow_bottom : 'no_bottom',
          is_bottomInfinite ? 'bottom_infinite' : null
        )}
      >
        {arrow_top && arrow_top != 'right' ? (
          <div className={classNames('bg_arrow_pointer_top', bg_color)}>
            <BGArrowPointerTopLeft />
          </div>
        ) : arrow_top === 'right' ? (
          <div className={classNames('bg_arrow_pointer_top', bg_color)}>
            <BGArrowPointerTopRight />
          </div>
        ) : null}
        <div className="center_content">{children}</div>
        {arrow_bottom && arrow_bottom != 'right' ? (
          <div className={classNames('bg_arrow_pointer_bottom', bg_color, secondaryColor ? 'bg_' + secondaryColor : '')}>
            <BGArrowPointerBottomLeft />
          </div>
        ) : arrow_bottom === 'right' ? (
          <div className={classNames('bg_arrow_pointer_bottom', bg_color, secondaryColor ? 'bg_' + secondaryColor : '')}>
            <BGArrowPointerBottomRight />
          </div>
        ) : // bottomInversebg === 'inverseRight' ? (
          //   <div className={classNames('bg_arrow_pointer_bottom', bg_color)}>
          //     <BGArrowPointerTopRight />
          // </div>
          // ):
          null}
      </div>
    );
  }
}

ShapeBackground.defaultProps = {
  color: 'bleu',
};

export default ShapeBackground;
