import React, { useState, useEffect, useRef } from 'react';

import PropTypes from 'prop-types';

import { HorizontalYellowLineShape } from '../../Atoms/Icons/Icons';
import useMedias from '../../hooks/useMedias';
import { Button } from '../../Molecules/Button/Button';
import ShapeBackground from '../../Molecules/ShapeBackground/ShapeBackground';
import TitleSection from '../../Molecules/TitleSection/TitleSection';

import './HomeOurActivities.scss';
import { getSectionBG, getTextColor, tradDroiteGauche } from '../../Utils/Utils';

let classNames = require('classnames');

const HomeOurActivities = ({ data, imagesArray, customClass = "" }) => {
  const { processUrl, getImage } = useMedias();

  const [active, setActive] = useState(0);
  const [loop, setLoop] = useState();
  const [slides, setSlides] = useState([]);
  const slideArr = [];
  const timerDelay = 3000;
  const j = useRef(0);

  useEffect(() => {
    if (slides.length > 0) {
      setLoop(
        setInterval(function () {
          setActive(j.current);
          if (j.current == slides.length - 1) {
            j.current = -1;
          }
          j.current++;
        }, timerDelay)
      );
    }
    return () => clearInterval(loop);
  }, [slides]);

  useEffect(() => {
    data.relationships?.field_nos_activites?.forEach((item) => {
      slideArr.push({
        title: item.title,
        class: 'transport',
        link: '/' + item.path?.langcode + item.path?.alias,
        visuel: getImage(
          imagesArray,
          item?.relationships?.field_image?.drupal_internal__mid
        ),
        alt: item?.relationships?.field_image?.field_media_image?.alt || ''
      });
    });
    setSlides(slideArr);
  }, [])

  const addActiveClass = (e, i) => {
    e.preventDefault();
    j.current = i;
    setActive(i);
    clearInterval(loop);
  };
  return (
    <section
      className={classNames(
        'home_our_activities',
        'section_content',
        customClass
      )}
    >
      <ShapeBackground
        top={tradDroiteGauche(data?.field_position_bordure_haut)}
        color={getSectionBG(data?.field_backgroud_color)}
        bottomInfinite={data.field_infinite_border}
        bottom={tradDroiteGauche(data?.field_position_bordure_bas)}
      >
        <div className="wrapper_page_xs">
          <TitleSection
            title={data.field_title?.processed}
            subtitle={data.field_subtitle?.processed}
            description={data.body?.processed}
            type="arrow"
            color={getTextColor(getSectionBG(data?.field_backgroud_color))}
          />
        </div>
        {
          slides.length > 0 &&
          <div className="wrapper_our_activities">
            <div className="menu" role="tablist" aria-label={data.field_title.processed}>
              {slides.map((menu_our_activitie, i) => (
                <div className="menu-item" key={i}>
                  <button
                    href="#"
                    aria-selected={active === i}
                    aria-controls={`tab-panel-${i}`}
                    className={classNames({ active: active === i })}
                    onClick={(e) => addActiveClass(e, i)}
                    role='tab'
                  >
                    <span>{menu_our_activitie.title}</span>
                    {active === i && <HorizontalYellowLineShape />}
                  </button>
                </div>
              ))}
            </div>
            <div className="visuel_text_container">
              {slides.map((menu_our_activitie_, k) => (
                <div
                  className={classNames('visuel_text', { show: active === k })}
                  role='tabpanel'
                  id={`tab-panel-${k}`}
                  key={k}
                >
                  <div className="visuel">
                    <img
                      src={processUrl(menu_our_activitie_.visuel?.image_style_uri?.gatsby_activites_carousel)}
                      alt={menu_our_activitie_.alt}
                      aria-hidden="true"
                      style={{
                        width: '100%',
                        height: '570px',
                        objectFit: 'cover',
                      }}
                    />
                  </div>
                  <div className="text">
                    <p>{menu_our_activitie_.title} </p>
                    <Button
                      label={data.field_link_title}
                      size="medium"
                      link={menu_our_activitie_.link}
                      target="_self"
                      aria-label={`${data.field_link_title} ${menu_our_activitie_.title}`}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        }

      </ShapeBackground>
    </section>
  );
};

HomeOurActivities.propTypes = {
  data: PropTypes.object.isRequired,
  imagesArray: PropTypes.array.isRequired,
};

export default HomeOurActivities;
