import * as React from 'react';

import PropTypes from 'prop-types';

import { RoundedArrow } from '../../Atoms/Icons/Icons';

import './TitleBlock.scss';
import { removeHtmlTag } from '../../Utils/Utils';

let classNames = require('classnames');

function TitleBlock({ title, subtitle, color, headingTag, titleBackground, titleIconColor, rtl }) {
  if (!title && !subtitle) { return <></> }
  return (
    <div className={classNames('title_block', { 'title_bg': titleBackground }, { 'rtl': rtl })}>
      {
        !titleBackground && (
          <RoundedArrow color={titleIconColor ? titleIconColor : 'color_dark_bleu'} />
        )
      }
      {headingTag === 'p' ? (
        <p className={`${color}`}>
          <span dangerouslySetInnerHTML={{ __html: title }}></span>
        </p>
      ) : (
        <div className='title_subtitle'>
          <h3 className={color || ''}>
            <span dangerouslySetInnerHTML={{ __html: title }}></span>
          </h3>
          {
            subtitle && (
              <h5
                className={classNames(color || '')}
                dangerouslySetInnerHTML={{ __html: removeHtmlTag(subtitle) }}
              ></h5>
            )

          }
        </div>
      )}
    </div>
  );
}

TitleBlock.defaultProps = {
  headingTag: 'h3',
  color: 'color_dark_bleu',
  titleBackground: false
};

TitleBlock.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
  headingTag: PropTypes.string,
  titleBackground: PropTypes.bool
};

export default TitleBlock;
