import React, { useRef, useState, useEffect } from 'react';

import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import { useIntl } from '../../../../plugins/publicis-gatsby-plugin-i18n';
import QuoteMark from '../../../Atoms/QuoteMark/QuoteMark';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import DiscoverStores from '../../../components/DiscoverStores/DiscoverStores';
import HomeBanner from '../../../components/HomeBanner/HomeBanner';
import HomeColasInnov from '../../../components/HomeColasInnov/HomeColasInnov';
import HomeJoinUs from '../../../components/HomeJoinUs/HomeJoinUs';
import HomeNews from '../../../components/HomeNews/HomeNews';
import HomeOurActivities from '../../../components/HomeOurActivities/HomeOurActivities';
import Seo from '../../../components/Seo/Seo';
import Layout from '../../../Layout';
import ShapeBackground from '../../../Molecules/ShapeBackground/ShapeBackground';
import TextVisuelCTATwoCol from '../../../components/ContentType/TextVisuelCTATwoCol/TextVisuelCTATwoCol';
import useMedias from '../../../hooks/useMedias';
import { getReOrderedCarouselSlides, getSectionBG, getTextColor, tradDroiteGauche } from '../../../Utils/Utils';
import TextVisuelTwoCol from '../../../components/ContentType/TextVisuelTwoCol/TextVisuelTwoCol';
import WebsiteList from '../../../components/WebsiteList/WebsiteList';
import BlockSimpleTextVisuelButtonWebsite from '../../../components/ContentType/BlockSimpleTextVisuelButtonWebsite/BlockSimpleTextVisuelButtonWebsite';
import TitleSection from '../../../Molecules/TitleSection/TitleSection';
import TitleBlock from '../../../Molecules/TitleBlock/TitleBlock';
import Carousel from '../../../components/Carousel/Carousel';
import DiscoverOfferBox from '../../../Molecules/DiscoverOfferBox/DiscoverOfferBox';

let classNames = require('classnames');
const IndexPage = ({ data, pageContext }) => {
  const intl = useIntl();

  const bannerRef = useRef(null);
  const { getImage } = useMedias();

  const [stickyTop, setStickyTop] = useState(0);
  const [stickyFixed, setStickyFixed] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [footerEl, setFooterEl] = useState(null);
  const [scrollTop, setScrollTop] = useState(0);
  const [stickyVisible, setStickyVisible] = useState(false);
  const [pageScrolled, setPageScrolled] = useState(false);

  const { locale } = pageContext;
  const homeNodesData = data.homeNodesData?.edges[0];
  const homeBlocksList = homeNodesData?.node?.relationships?.field_blocs;
  const imagesArray = data.allImages.edges;
  const videosArray = data.allVideos.edges;
  const promotedNews = data.promotedNews.edges;
  const eventBannerData = data.eventBannerData?.edges[0]?.node?.relationships?.field_blocs.filter((block) => block.__typename === "block_content__block_banniere_evenementielle");
  const metaTags = data.homeNodesData?.edges[0].node.metatag;
  let metaTitle = '';
  let metaDesc = '';

  metaTags.forEach((meta) => {
    if (meta.attributes.name === 'title') {
      metaTitle = meta.attributes.content;
    }
    if (meta.attributes.name === 'description') {
      metaDesc = meta.attributes.content;
    }
  });
  useEffect(() => {
    const is_event_banner = document.querySelector('#header_container')?.classList.contains('header_with_event_banner');
    setHeaderHeight(window.innerWidth > 1023 ? 110 + (is_event_banner ? 160 : 0) : 61);
    setFooterEl(document.querySelector('#footer'));
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);
    handleResize();
    setStickyVisible(true);
    return () => {
      document.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [headerHeight]);

  const handleResize = () => {
    const is_event_banner = document.querySelector('#header_container')?.classList.contains('header_with_event_banner');
    setHeaderHeight(window.innerWidth > 1023 ? 110 + (is_event_banner ? 160 : 0) : 61);
    if (!bannerRef.current) {
      return false;
    }
    if (
      bannerRef.current.offsetHeight + headerHeight >
      window.innerHeight
    ) {
      if (
        scrollTop + window.innerHeight >
        bannerRef.current.offsetHeight + headerHeight + 230
      ) {
        setStickyTop(bannerRef.current.offsetHeight - headerHeight + 100);
        setStickyFixed(true);
      } else {
        setStickyTop(bannerRef.current.offsetHeight);
        setStickyFixed(false);
      }
    } else {
      setStickyTop(bannerRef.current.offsetHeight + headerHeight);
      setStickyFixed(true);
    }
  };
  const loadVideo = (val) => {
    setVideoId(val);
    setStopVideo(false);
    document.body.classList.add('show_video_popin');
  }
  const closePopinVideo = () => {
    document.body.classList.remove('show_video_popin');
    setStopVideo(true);
    if (document.querySelector('a.accessibilite')) {
      document.querySelector('a.accessibilite').focus();
    }
  }
  const handleScroll = () => {
    setScrollTop(window.pageYOffset);
  };

  useEffect(() => {
    setPageScrolled(scrollTop > headerHeight);
    if (
      bannerRef?.current?.offsetHeight + headerHeight >
      window.innerHeight
    ) {
      if (
        scrollTop + window.innerHeight >
        bannerRef.current.offsetHeight + headerHeight + 230
      ) {
        setStickyTop(bannerRef.current.offsetHeight - headerHeight + 100);
        setStickyFixed(true);
      } else {
        setStickyTop(bannerRef.current.offsetHeight);
        setStickyFixed(false);
      }
    }
    if ((footerEl?.getBoundingClientRect().top + 50) < window.innerHeight) {
      setStickyVisible(false);
    } else {
      setStickyVisible(true);
    }

  }, [scrollTop]);
  return (
    <Layout
      eventBannerData={eventBannerData ? eventBannerData[0] : null}
      imagesArray={imagesArray}
    >
      <Seo
        title={homeNodesData?.node?.field_metatag?.title ? homeNodesData?.node?.field_metatag?.title : metaTitle}
        description={homeNodesData?.node?.field_metatag?.description ? homeNodesData?.node?.field_metatag?.description : metaDesc}
      />
      <div className={classNames('page_template', 'zero_bottom_padding')}>
        {homeBlocksList?.map((block, i) => {
          switch (block.__typename) {
            case 'block_content__main_banner':
              return (
                <div className="home_banner" ref={bannerRef} key={i}>
                  <HomeBanner
                    homeBannerNode={block}
                    imagesArray={imagesArray}
                    videosArray={videosArray}
                  >
                    <div className="wrapper_page">
                      <Breadcrumb
                        page_banner={true}
                        data={{
                          currentPage: {
                            title: intl.formatMessage({
                              id: 'common.goto.home',
                            }),
                            url: '/',
                          },
                          locale,
                        }}
                      />
                      <div className="intro">
                        <QuoteMark
                          text={block.field_title.processed}
                          tag="h1"
                          lang={locale}
                        />
                        {
                          block?.body?.processed &&
                          <div
                            dangerouslySetInnerHTML={{
                              __html: block.body.processed,
                            }}
                          ></div>
                        }

                      </div>
                    </div>
                  </HomeBanner>
                </div>
              );

            case 'block_content__block_slider_innovation': //Bloc Slider Avec ancre
              let slides = [];
              block.relationships?.field_slides?.forEach((item) => {
                slides.push({
                  image: getImage(
                    imagesArray,
                    item?.relationships?.field_image?.drupal_internal__mid
                  ),
                  alt: item.relationships?.field_image?.field_media_image?.alt || '',
                  info: {
                    text: item.field_title?.processed,
                    link: item.field_link?.url,
                    link_label: block.field_link?.title,
                    anchor: item?.relationships?.field_anchor ? '?anchor=section-' + item?.relationships?.field_anchor?.drupal_internal__tid : null
                  }
                });
              });
              slides.push(...slides);
              return (
                <section
                  className={classNames(
                    'section_content',
                    'block_content__block_slider_innovation',
                    (homeBlocksList[i + 1]?.field_position_bordure_haut === 'droite') ? 'z_index_2' : ''
                  )}
                  key={i}
                >
                  <ShapeBackground
                    top={tradDroiteGauche(block?.field_position_bordure_haut)}
                    color={getSectionBG(block.field_backgroud_color)}
                    bottomInfinite={block.field_infinite_border}
                    bottom={tradDroiteGauche(block?.field_position_bordure_bas)}
                    secondaryColor={getSectionBG(block.field_secondary_background)}
                  >
                    {
                      block?.field_slider_big ? (
                        <HomeColasInnov
                          data={block}
                          imagesArray={imagesArray}
                        />
                      ) : (
                        <>
                          <div className={classNames('wrapper_page_xs', 'only_desktop')}>
                            <TitleSection
                              title={block.field_title?.processed}
                              subtitle={block.field_subtitle?.processed}
                              description={block.body?.processed}
                              color={getTextColor(getSectionBG(block.field_backgroud_color))}
                              theme={block.field_title_type}
                            />
                            <Carousel
                              slides={getReOrderedCarouselSlides(slides)}
                              textColor={getTextColor(getSectionBG(block.field_backgroud_color))}
                              openVideo={loadVideo}
                              socialShareIcons={pageContext['config_data_' + locale]}
                              only_images={block.field_slider_only_images}
                            />
                          </div>
                          <div className='only_mobile_tablet'>
                            <HomeColasInnov
                              data={block}
                              imagesArray={imagesArray}
                              key={i}
                            />
                          </div>
                        </>
                      )
                    }

                  </ShapeBackground>
                </section>
              );

            case 'block_content__block_slider_nos_activites': //Block Slider de contenus existant
              return (
                <HomeOurActivities
                  data={block}
                  customClass={homeBlocksList[i + 1]?.__typename === "block_content__block_cta_avec_image_de_fond" ? "contain_cta_avec_image_de_fond" : ""}
                  imagesArray={imagesArray}
                  key={i}
                />
              );

            case 'block_content__block_actualites':
              // console.log(promotedNews)
              return (
                <HomeNews
                  newsNode={block}
                  topNews={promotedNews}
                  imagesArray={imagesArray}
                  documentsArray={data.allDocuments?.edges}
                  key={i}
                  socialShareIcons={pageContext['config_data_' + locale]}
                />
              );
            case 'block_content__basic':
              const webSiteDetails = {
                legend: block?.field_legend?.processed,
                field_sites_web: block?.relationships?.field_sites_web
              }
              return (
                <section
                  className={classNames(
                    'section_content',
                    'block_content__basic',
                    (block.field_secondary_background ? 'zero_margin_bottom' : '')
                  )}
                  key={i}
                >
                  <ShapeBackground
                    top={tradDroiteGauche(block?.field_position_bordure_haut)}
                    color={getSectionBG(block.field_backgroud_color)}
                    bottomInfinite={block.field_infinite_border}
                    bottom={tradDroiteGauche(block?.field_position_bordure_bas)}
                    secondaryColor={getSectionBG(block.field_secondary_background)}
                  >
                    {(() => {
                      switch (block.field_appearance) {
                        case 'two_column_format':
                          return (
                            <div className="wrapper_page">
                              <TextVisuelCTATwoCol
                                extremeLeft={
                                  block?.field_no_padding &&
                                    block?.field_alignment === 'left'
                                    ? true
                                    : false
                                }
                                color={'color_' + getTextColor(getSectionBG(block.field_backgroud_color))}
                                extremeRight={
                                  block?.field_no_padding &&
                                    block?.field_alignment === 'left'
                                    ? false
                                    : true
                                }
                                orderInverse={
                                  block?.field_alignment === 'right' ? true : false
                                }
                                visuel={getImage(
                                  imagesArray,
                                  block.relationships?.field_image?.drupal_internal__mid
                                )}
                                alt={block.relationships?.field_image?.field_media_image?.alt || ''}
                                alignTop={true}
                                title={{
                                  titleSize: block.field_title_type === 'theme_1' ? 'h2' : 'h3',
                                  titleText: block.field_title?.processed,
                                  subtitle: block.field_subtitle?.processed,
                                }}
                                text={block.body.processed}
                                cta={{
                                  ctaText: block.field_link?.title,
                                  ctaLink: block.field_link?.url,
                                }}
                              />
                            </div>
                          )
                        case 'two_column_format_title_outside':
                          return (
                            <div className="wrapper_page_xs">
                              {
                                block.field_title_type === 'theme_2' ?
                                  <TitleBlock
                                    title={block.field_title?.processed}
                                    subtitle={block.field_subtitle?.processed}
                                    color={'color_' + getTextColor(getSectionBG(block.field_backgroud_color))}
                                    headingTag='h3'
                                    titleBackground={false}
                                  />
                                  :
                                  <TitleSection
                                    title={block.field_title?.processed}
                                    subtitle={block.field_subtitle?.processed}
                                    color={getTextColor(getSectionBG(block.field_backgroud_color))}
                                    theme={block.field_title_type}
                                  />
                              }
                              <TextVisuelTwoCol
                                orderInverse={
                                  block.field_alignment === 'right'
                                    ? true
                                    : false
                                }
                                extremeLeft={
                                  block?.field_no_padding &&
                                    block?.field_alignment === 'left'
                                    ? true
                                    : false
                                }
                                extremeRight={
                                  block?.field_no_padding &&
                                    block?.field_alignment === 'right'
                                    ? true
                                    : false
                                }
                                visuel={getImage(
                                  imagesArray,
                                  block?.relationships?.field_image?.drupal_internal__mid
                                )}
                                alt={block?.field_title?.processed}
                                text={block.body.processed}
                                colorTitle={'color_' + getTextColor(getSectionBG(block.field_backgroud_color))}
                                colorDescription={'color_' + getTextColor(getSectionBG(block.field_backgroud_color))}
                              />
                            </div>
                          )
                        default:
                          return (
                            <div className="wrapper_page_xs">
                              <BlockSimpleTextVisuelButtonWebsite data={block} imagesArray={imagesArray} />
                            </div>
                          )
                          break;
                      }
                    })()}
                    <div className="wrapper_page">
                      {webSiteDetails?.field_sites_web?.length > 0 &&
                        <WebsiteList
                          componentData={webSiteDetails}
                          imagesArray={imagesArray}
                          alignment={data.imageAlignment}
                          color={getTextColor(getSectionBG(block.field_backgroud_color))}
                        />
                      }
                    </div>

                  </ShapeBackground>
                </section>
              );
            case 'block_content__block_cta_avec_image_de_fond':
              return (
                <section
                  className={classNames(
                    'section_content',
                    'block_content__block_cta_avec_image_de_fond',
                    { 'collapse_with_bottom_section': block?.field_no_padding }
                  )}
                  key={i}
                >
                  <div className="wrapper_page">
                    <DiscoverOfferBox
                      visuel={getImage(
                        imagesArray,
                        block?.relationships?.field_image
                          ?.drupal_internal__mid
                      )}
                      alt={block?.relationships?.field_image?.field_media_image?.alt || ''}
                      label={block?.field_link?.title}
                      link={block?.field_link?.url}
                      target={
                        block?.field_nouvelle_fenetre ? '_blank' : '_self'
                      }
                    />
                  </div>
                </section>
              );

            case 'block_content__block_nous_rejoindre':
              return <HomeJoinUs data={block} key={i} />;

            default:
              break;
          }
        })}
        {
          homeNodesData?.node?.field_display_popin &&
          <div
            className={classNames('discover_store_sticky', {
              hide: !stickyVisible,
            })}
            style={{
              top: `calc(${stickyTop}px)`,
              position: stickyFixed ? 'fixed' : 'absolute',
            }}
          >
            <DiscoverStores sticky={true} smaller={pageScrolled} />
          </div>
        }

      </div>
    </Layout>
  );
};

export const query = graphql`
  query HomePageTemplate(
    $locale: String!
    $imagesID: [Int!]
    $videoID: [Int!]
    $documentsID: [Int!]
  ) {
    homeNodesData: allNodeHomepage(
      filter: { langcode: { eq: $locale }, status: { eq: true } }
    ) {
      edges {
        node {
          status
          langcode
          field_metatag {
            description
            title
          }
          metatag {
            attributes {
              content
              name
            }
          }
          field_display_popin
          relationships {
            field_blocs {
              __typename
              ... on Node {
                ... on block_content__main_banner {
                  field_title {
                    processed
                  }
                  body {
                    processed
                  }
                  field_type_de_media
                  relationships {
                    field_image {
                      drupal_internal__mid
                      field_media_image{
                        alt
                      }
                    }
                    field_mobile_image {
                      drupal_internal__mid
                      field_media_image{
                        alt
                      }
                    }
                    field_video {
                      drupal_internal__mid
                    }
                  }
                }
                ... on block_content__block_slider_innovation {
                  id
                  field_title {
                    processed
                  }
                  field_subtitle {
                    processed
                  }
                  body {
                    processed
                  }
                  field_backgroud_color
                  field_secondary_background
                  field_position_bordure_haut
                  field_position_bordure_bas
                  field_infinite_border
                  field_slider_big
                  field_slider_only_images
                  relationships {
                    field_slides {
                      field_title {
                        processed
                      }
                      field_link{
                        url
                      }
                      field_new_tab
                      relationships {
                        field_image {
                          drupal_internal__mid
                          field_media_image{
                            alt
                          }
                        }
                        field_anchor {
                          drupal_internal__tid
                        }
                      }
                    }
                  }
                }
                ... on block_content__block_slider_nos_activites {
                  id
                  field_title {
                    processed
                  }
                  field_subtitle {
                    processed
                  }
                  body {
                    processed
                  }
                  field_backgroud_color
                  field_secondary_background
                  field_infinite_border
                  field_position_bordure_haut
                  field_position_bordure_bas
                  field_link_title
                 
                  relationships {
                    field_nos_activites {
                      __typename
                      ... on Node {
                        ... on node__page{
													title
                          body{
														summary
                          }
                          path{
														alias
                            langcode
                          }
                          relationships{
														field_image{
															drupal_internal__mid
                              field_media_image{
                                alt
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                ... on block_content__block_actualites {
                  id
                  body {
                    processed
                  }
                  field_backgroud_color
                  field_infinite_border
                  field_position_bordure_haut
                  field_position_bordure_bas
                  field_link {
                    title
                    uri
                    url
                  }
                  field_title {
                    processed
                  }
                  drupal_internal__id
                }
                ... on block_content__basic {
                  id
                  field_backgroud_color
                  field_secondary_background
                  field_position_bordure_haut
                  field_position_bordure_bas
                  field_infinite_border
                  field_no_padding
                  field_alignment
                  field_appearance
                  field_subtitle {
                    processed
                  }
                  field_title_type
                  field_title {
                    processed
                  }
                  body{
                    processed
                  }
                  field_legend {
                    processed
                  }
                  relationships {
                    field_image {
                      drupal_internal__mid
                      field_media_image{
                        alt
                      }
                    }
                    field_sites_web {
                      field_text {
                        processed
                      }
                      field_subtitle
                      field_link {
                        url
                      }
                      field_theme_style
                      relationships {
                        field_image {
                          drupal_internal__mid
                          field_media_image{
                            alt
                          }
                        }
                      }
                    }
                  }
                  field_link {
                    title
                    url
                  }
                }
                ... on block_content__block_cta_avec_image_de_fond {
                  field_link {
                    title
                    uri
                    url
                  }
                  field_nouvelle_fenetre
                  field_no_padding
                  relationships {
                    field_image {
                      drupal_internal__mid
                      field_media_image{
                        alt
                      }
                    }
                  }
                }
                ... on block_content__block_nous_rejoindre {
                  field_title {
                    processed
                  }
                  field_subtitle {
                    processed
                  }
                  body {
                    processed
                  }
                  field_aria_label
                  drupal_internal__id
                  field_offre_text
                  field_link {
                    title
                    url
                  }
                  field_chiffre_offre
                  field_backgroud_color
                  field_position_bordure_haut
                  field_position_bordure_bas
                  field_infinite_border
                }
              }
            }
          }
        }
      }
    }

    promotedNews: allNodeArticle(
      filter: {
        promote: { eq: true }
        status: { eq: true }
        langcode: { eq: $locale }
      }
      sort: { fields: created, order: DESC }
    ) {
      edges {
        node {
          id
          title
          sticky
          status
          langcode
          promote
          path {
            langcode
            alias
          }
          fields {
            readingTime {
              format
              minutes
              seconds
            }
          }
          relationships {
            field_category {
              drupal_internal__tid
            }
            field_media_pdf {
              drupal_internal__mid
            }
            field_image {
              drupal_internal__mid
              field_media_image{
                alt
              }
            }
          }
        }
      }
    }

    allImages: allMediaImage(
      filter: {
        langcode: { eq: "fr" }
        drupal_internal__mid: { in: $imagesID }
      }
    ) {
      edges {
        node {
          drupal_internal__mid
          relationships {
            field_media_image {
              uri {
                url
              }
              image_style_uri {
                gatsby_thumbnail
                gatsby_medium
                gatsby_large
                gatsby_wide
                gatsby_medium_carousel
                gatsby_thumbnail_carousel
                gatsby_innov_carousel
                gatsby_activites_carousel
                gatsby_profile_large
                gatsby_profile_thumbnail
                webp
              }
            }
          }
        }
      }
    }
    allVideos: allMediaVideo(
      filter: { langcode: { eq: "fr" }, drupal_internal__mid: { in: $videoID } }
    ) {
      edges {
        node {
          drupal_internal__mid
          relationships {
            field_media_video_file {
              uri {
                url
              }
            }
          }
          langcode
        }
      }
    }
    allDocuments: allMediaDocument(
      filter: {
        langcode: { eq: "fr" }
        drupal_internal__mid: { in: $documentsID }
      }
    ) {
      edges {
        node {
          langcode
          field_media_document {
            description
          }
          relationships {
            field_media_document {
              filesize
              filename
              filemime
              uri {
                url
              }
            }
          }
          drupal_internal__mid
        }
      }
    }
    eventBannerData: allNodeHomepage(filter: { langcode: { eq: $locale }, status: { eq: true } }){
      edges {
        node {
          relationships {
            field_blocs {
              __typename
              ... on Node {
                ... on block_content__block_banniere_evenementielle{
									body {
                      processed
                    }
                    field_lien {
                      title
                      url
                    }
                    field_aria_label
                    field_display_logo
                    field_display
                    langcode
                    relationships {
                      field_image {
                        drupal_internal__mid
                        field_media_image{
                          alt
                        }
                      }
                    }
                }
              }
            }
          }
        }
      }
    }
  }
`;

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export default IndexPage;
